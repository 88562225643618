<template>
  <div class="list">
    <div class="list_b">
      <ul>
        <li>
          <p>主账号编号：</p>
          <input type="text" @keypress.enter="getData(1)" v-model="form.shuyuId">
        </li>
        <li>
          <p>门店名称：</p>
          <input type="text" @keypress.enter="getData(1)" v-model="form.fenpeimendianName">
        </li>
        <li>
          <p>VIP：</p>
          <select @change="getData(1)" v-model="form.isVip">
            <option :value="0">全部</option>
            <option :value="1">VIP</option>
            <option :value="2">非VIP</option>
          </select>
        </li>
        <li>
          <button type="button" @click="getData(1)">查询</button>
        </li>
        <div class="clear"></div>
      </ul>
    </div>
    <div class="home_list">
      <div class="list_d">
      <table>
        <thead>
          <tr>
            <td>门店名称</td>
            <td>门店位置（省、市、县）</td>
            <td>详细地址</td>
            <td>联系方式</td>
            <td>属于账号</td>
            <td>创建人</td>
            <td>创建时间</td>
            <td>到期时间</td>
            <td>短信数量</td>
            <td>状态</td>
            <td>操作</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in dataList" :key="item.id">
            <td>{{item.storeName}}</td>
            <td>{{item.storeProvince}}{{item.storeCity?'-'+item.storeCity:''}}{{item.storeCounty?'-'+item.storeCounty:''}}</td>
            <td>{{item.storeAddress}}</td>
            <td>{{item.iPhone}}</td>
            <td>
              <div class="buttonGroup">
                <button type="button" class="pur" @click="toUser(item.shuyuId)">查看</button>
              </div>
            </td>
            <td>{{item.chuangjianrenName}}</td>
            <td>{{getDateTime(item.chuangjianshijian)}}</td>
            <td>{{getShengri(item.daoqishijian)}}</td>
            <td>{{item.mesNum}}</td>
            <td><span :class="item.state==1?'suc':'err'">{{item.state==1?"正常":"删除"}}</span></td>
            <td>
              <div class="buttonGroup">
                <button type="button" class="suc" @click="setInfoData(item)">管理</button>
              </div>
            </td>
          </tr>
          
        </tbody>
      </table>
    </div>
    </div>
    <div class="list_c">
      <Page :pageData="pageData" @resetData="resetData"></Page>
      <div class="clear"></div>
    </div>
    <PSinfo :title="title" v-if="infoShow" :MmsData="MmsData" @closedWindow="closedWindow"></PSinfo>
  </div>
</template>

<script>
import PSinfo from './PSinfo.vue'
import axios from '@/http/axios'
import moment from 'moment'
export default {
  name: 'packageSettings',
  data() {
    return {
      infoShow: false,
      title:'门店管理',
      dataList:[],
      form:{
        shuyuId:null,
        fenpeimendianName:'',
        isVip:0,
      },
      pageData:{
        pageSize:20,
        pageIndex:1,
        total:0,
      },
      MmsData:{
        new:true,
      },
    }
  },
  created() {
    if(this.$route.query.id) this.form.shuyuId=parseInt(this.$route.query.id)
    this.getData(1)
  },
  components: {PSinfo},
  mounted() {},
  methods: {
    getShengri(sr){
      if(sr) return moment(sr).format("YYYY-MM-DD")
      else return ''
    },
    getDateTime(sr){
      if(sr) return moment(sr).format("YYYY-MM-DD HH:mm:ss")
      else return ''
    },
    setInfoData(item){
      this.MmsData.new=false
      this.MmsData.data=item
      this.infoShow=true
    },
    toUser(id){
      if(id){
        this.$router.push({path:"/allUser",query:{id}})
      }
    },
    getData(index){
      if(index) this.pageData.pageIndex=1
      axios.post('/manage/allMendianListPage',{...this.pageData,...this.form}).then((res) => {
        if(res.code===5000){
          this.dataList=res.data
          this.pageData.total=res.total
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    resetData(data){
      this.pageData=data
      this.getData()
    },
    closedWindow(){
      this.infoShow=false
    },
  },
}
</script>
