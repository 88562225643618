<template>
  <div>
    <RightWindow :title="title" @closedWindow="closedWindow">
        <div class="center_b_2">
          <ul class="ultab">
            <li class='hover'><a href="javascript:void(0)">详细信息</a></li>
          </ul>
        </div>
        <div class="center_b_3">
          <ul>
            <li class="up"><span>门店名称：</span>{{form.storeName}}</li>
            <li class="up"><span class="import">到期时间：</span><input type="date" v-model="form.daoqishijian"/></li>
            <li class="up"><span class="import">余短信数：</span><input type="number" v-model="form.mesNum"/></li>
          </ul>
          <div class="center_b_4">
            <button class="settab" @click="subData">保存</button>
            <button class="remole" @click="closedWindow">取消</button>
          </div>
        </div>
    </RightWindow>
  </div>

</template>

<script>
import axios from '@/http/axios'
import {deepCopy} from '@/utils/util'
import moment from 'moment'
export default {
  name: 'PSinfo',
  props:{
    MmsData:Object,
    title:String,
  },
  watch:{
    'MmsData':{
      handler(newD){
        this.form=deepCopy(newD.data)
        if(this.form.daoqishijian) this.form.daoqishijian=moment(this.form.daoqishijian).format("YYYY-MM-DD")
      },
      deep:true
    }
  },
  data() {
    return {
      form:{},
    }
  },
  created() {
    this.init()
  },
  components: {},
  mounted() {},
  methods: {
    init(){
      this.form=deepCopy(this.MmsData.data)
      if(this.form.daoqishijian) this.form.daoqishijian=moment(this.form.daoqishijian).format("YYYY-MM-DD")
    },

    closedWindow(){
      this.$emit('closedWindow')
    },
    subData(){
      axios.post('/manage/mendianEdit',this.form).then((res) => {
        if(res.code===5000){
          this.$Mesg({content: '修改门店成功'})
          this.$parent.getData()
          this.closedWindow()
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
  },
}
</script>
